@import "../../../../colors";

.ProgressRing {
  position: relative;

  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .progress-percentage {
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    text-align: center;
    background-color: #fff;
    border-radius: 50%;

    .progress-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .progress-text-container {

        .completion-amount {
          color: $primaryColor;
          margin-top: 0;
          font-weight: bold;
        }

        .completion-text {
          font-size: 8px;
        }
      }
    }
  }
}
