@import "../../colors";

.Authed {
  position: relative;

  .main-container {
    display: flex;
    flex-direction: row;

    .nav-container {
      width: 200px;
      position: relative;
      z-index: 10;
      background-color: #fff;

      .nav-inner {
        position: fixed;
        height: 100vh;
        overflow-y: auto;
        width: 200px;
        left: 0;
        top: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        background-color: $primaryColorLight;
        transition: transform 0.2s;

        .logo-container {
          text-align: center;
          height: 64px;

          .logo {
            height: 40px;
            margin-top: 12px;
          }

          .close {
            display: none;
            height: 29px;
          }
        }
      }
    }
  
    .content-container {
      margin-top: 60px;
      padding-bottom: 70px;
      width: calc(100% - 200px);
  
      .perfect-pup-banner {
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        padding: 10px 20px;
        margin-top: -8px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: bold;
  
        .perfect-pup-message {
  
          .Loader {
            margin-left: 6px;
          }
          
          span {
            color: #2BB673;
            cursor: pointer;
            margin-left: 6px;
            text-decoration: underline;
          }
        }
      }
  
      .content-inner {
        width: 100%;
      }
    }
  
    @media (max-width: 900px) {
      .content-container {
        width: 100%;
      }

      .nav-container {
        width: 0;

        .nav-inner {

          &.closed {
            transform: translateX(-201px);
          }

          .logo-container {
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 64px;
  
            .logo {
              height: 40px;
              margin-top: 0;
            }

            .close {
              display: flex;
              font-size: 27px;
              cursor: pointer;
              margin-right: 21px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
