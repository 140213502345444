.ForgotPassword {
  position: relative;
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;

  .form-container {
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    width: 700px;
    padding: 20px;

    h1 {
      border-bottom: 4px solid #ff3312;
      padding-bottom: 10px;
      display: inline-block;
      margin-bottom: 15px;
    }

    p {
      line-height: 22px;
    }

    form {
      margin-top: 30px;

      input[type=email] {
        padding-top: 13px;
        padding-bottom: 13px;
      }

      input[type=submit] {
        border: none;
        font-weight: 700;
        border-radius: 4px;
        width: 100%;
        background-color: #ff3312;
        color: #fff;
        font-size: 24px;
        padding: 11px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        margin-top: 10px;
      }

      input[type=submit]:hover {
        opacity: .8;
      }

      input[type=submit]:focus {
        outline-width: 0;
      }

      .loading-container {
        text-align: center;
        margin-top: 28px;
        margin-bottom: 5px;
      }
    }

    .return-to-login {
      text-align: center;
      margin-top: 40px;

      a {
        color: #0072bc;
      }
    }
  }

  @media (max-width: 740px) {
    .form-container {
      margin-left: 0;
      transform: none;
      width: calc(100% - 40px);
    }
  }
}
