@import "../../../../../colors";

.SoundsRecording {
  display: flex;
  flex-direction: row;

  .left-container {
    flex: 1;
    text-align: center;
    margin-bottom: 20px;

    .left-container-inner {
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;

      @keyframes pulse-red {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
      }

      .record-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        transform: scale(1);
        background: $primaryColor;
        box-shadow: 0 0 0 0 $primaryColor;
        animation: pulse-red 2s infinite;
      }

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 40%;
        margin: 20px;
      }

      .sound-label-container {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 10px;

        p {
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .right-container {
    flex: 1;
    margin-left: 20px;

    .no-recording-container {
      text-align: center;

      p {
        font-weight: 600;
        line-height: 1.5;
      }
    }

    .recording-row-outer {
      margin-bottom: 5px;

      a {
        text-decoration: none;
      }

      .recording-row {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        align-items: center;
        cursor: default;
        position: relative;

        .popover {
          position: absolute;
          z-index: 1;
          background-color: #fff;
          padding: 5px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          left: 40px;
          top: 50%;
          transform: translateY(-50%);

          .popover-inner {
            display: flex;
            flex-direction: row;

            .success {
              margin-right: 5px;
            }
          }
        }

        .recording-left {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          i {
            padding: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            div {
              background-color: #000;
              height: 5px;
              width: 5px;
              border-radius: 5px;
              margin-top: 1px;
              margin-bottom: 1px;
            }
          }

          p {
            font-weight: bold;
            margin: 0 10px;
            color: #000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .play-icon-container {
          background-color: #ff3312;
          height: 50px;
          width: 50px;
          border-radius: 14px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          i {
            font-size: 24px;
            color: #fff;
            font-style: normal;

            &:after {
              content: '\25B6';
            }
          }
        }
      }
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;

    .right-container {
      margin-left: 0;
    }
  }
}

.save-recording-modal-container {
  margin: -15px 0;

  audio {
    width: 100%;
  }
}