@import "../../../../colors";

.Dashboard {
  position: relative;
  padding: 15px 10px;

  .loading-container {
    text-align: center;
    padding-top: 60px;
    height: 100vh;
    width: 100%;

    p {
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      color: #333;
    }
  }

  .next-module-container {
    background-color: #fff;
    padding-top: 43%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
    position: relative;
  }

  .next-module-actions {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    .next-module-text-container {
      margin-right: 10px;

      .next-module-title {
        color: $primaryColor;
        margin: 0 0 5px 0;
        font-weight: 600;
      }

      .next-module-description {
        font-size: 20px;
        margin: 0;
        font-weight: 600;
      }
    }
  }

  .training-tools {

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 30px;

      p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
      }

      a {
        color: $primaryColor;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .training-tools-row {
      padding: 20px 0;
      width: 100%;
      display: flex;
      align-items: stretch;
      overflow-x: auto;

      .training-tool {
        width: 225px;
        margin-right: 10px;
        flex-shrink: 0;
        cursor: pointer;

        .Card {
          box-shadow: none;
        }

        .training-tool-inner {
          padding: 10px;

          img {
            width: 100%;
            display: block;
          }

          p {
            text-decoration: none;
            color: #000;
            font-size: 18px;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .recommended-modules {

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 20px;

      p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
      }
    }


    .recommended-modules-row {
      padding: 20px 0;
      width: 100%;
      display: flex;
      align-items: stretch;
      overflow-x: auto;

      .recommended-module {
        width: 225px;
        margin-right: 10px;
        flex-shrink: 0;

        .Card {
          box-shadow: none;
        }

        .recommended-module-inner {
          padding: 10px;

          .image-background {
            background-color: #fff;
            padding-top: 100%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: cover;
            position: relative;
          }

          .module-title {
            text-decoration: none;
            color: $primaryColor;
            margin: 10px 0 0;
            font-weight: 600;
          }

          .module-description {
            text-decoration: none;
            color: #000;
            font-size: 18px;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
