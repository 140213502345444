@import "../../../../colors";

.Profile {
  padding: 15px 10px;

  .loading-container {
    text-align: center;
    margin-top: 60px;

    p {
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      color: #333;
    }
  }

  .profile-content {
    padding: 20px;
    position: relative;

    .profile-group {
      margin-bottom: 30px;

      .section-divider {
        border-bottom: 2px solid $borderDark;
      }

      a {
        text-decoration: none;
        width: max-content;
        display: block;
      }

      h3 img {
        height: 13px;
        margin-left: 5px;
        cursor: pointer;
      }

      p {
        color: #717171;
        margin-left: 20px;
        width: max-content;
      }

      .profile-image-container {
        background-color: #eee;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 20px;

        img {
          height: 100px;
          width: 100px;
          border-radius: 50%;

          &.paw-icon {
            height: 50px;
          }
        }
      }

      .dogs-profile-header-row {
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid $borderDark;
        border-left-width: 0;
        border-right-width: 0;

        .switch-dogs-button {
          padding: 0;
          background-color: rgba(0, 0, 0, 0);
          color: $linkColor;

          i {
            margin-right: 5px;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .link-item:hover {
        opacity: 0.8;
      }

      .behavior-row {
        margin-left: 20px;
        border-top: 1px solid $borderLight;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      .social-items {
        display: flex;

        a {
          margin-right: 12px;
          border-radius: 40px;

          .social-item {
            background-color: #000;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            position: relative;
            cursor: pointer;
    
            .social-image {
              height: 20px;
              filter: brightness(0) invert(1);
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              font-size: 20px;
            }
          }
  
          .social-item:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.dog-breed-select {
  margin-bottom: 15px;
}

.profile-image-upload-modal {
  padding: 50px;
  text-align: center;
  position: relative;
  background-color: rgba(0,0,0,0.03);
  border-radius: 4px;
  border: 1px dashed $primaryColor;

  &.drag-over {
    border-color: $successColor;
  }

  .profile-image-upload-dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.new-password-container {
  position: relative;

  .password-strength-container {
    position: absolute;
    bottom: 73px;
    background-color: #fff;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    font-size: 12px;

    .password-strength-container-inner {
      position: relative;

      .password-reset-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #bfbfbf;
        bottom: -24px;

        &.password-confirm-arrow {
          bottom: -17px;
        }
      }
    }
  }
}
