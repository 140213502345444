@import "../../../../colors";

.categoryShopPageContent {
  position: relative;
  padding-bottom: 70px;

  .loading-container {
    text-align: center;
    margin-top: 75px;

    p {
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      color: #333;
    }
  }

  .productsContainer {
    max-width: 1140px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    color: #592E2C;

    .topRowContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0;

      .filtersContainer {
        margin-top: 20px;

        .filtersSelectContainer {
          display: flex;
          flex-direction: row;
          margin-right: 10px;

          select {
            background: linear-gradient(45deg, transparent 50%, #592e2c 0), linear-gradient(135deg, #592e2c 50%, transparent 0), linear-gradient(90deg, transparent, transparent);
            background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
            background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
            background-repeat: no-repeat;
            line-height: 1.4;
            border-radius: 8px;
            position: relative;
            color: #592e2c;
            box-shadow: none;
            border: 2px solid #592e2c;
            background-color: transparent;
            padding: 8px 35px 8px 12px;
            width: 155px;
            font-size: 16px;
            margin: 0;
            box-sizing: border-box;
            line-height: 1.65;
            margin-left: 7px;
          }
        }
      }

      .sortContainer {
        width: 180px;
        margin-top: 20px;

        select {
          background: linear-gradient(45deg, transparent 50%, #592e2c 0), linear-gradient(135deg, #592e2c 50%, transparent 0), linear-gradient(90deg, transparent, transparent);
          background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
          background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
          background-repeat: no-repeat;
          line-height: 1.4;
          border-radius: 8px;
          position: relative;
          color: #592e2c;
          box-shadow: none;
          border: 2px solid #592e2c;
          background-color: transparent;
          padding: 8px 35px 8px 12px;
          width: 100%;
          font-size: 16px;
          margin: 0;
          box-sizing: border-box;
          line-height: 1.65;
          margin-left: 7px;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;

        .filtersContainer {
          margin-bottom: 20px;
        }
      }

      @media (max-width: 500px) {
        .filtersContainer .filtersSelectContainer {
          flex-direction: column;
          margin-right: 0;

          select {
            width: 100%;
            margin-right: 0;
          }

          select:first-of-type {
            margin-bottom: 10px;
          }
        }

        .sortContainer {
          width: 100%;

        }
      }
    }

    .noResultsContainer {
      text-align: center;
      margin-top: 50px;
    }

    .productsRow {
      margin: 0 -20px;
      text-align: center;

      .productContainer {
        display: inline-block;
        width: 25%;
        padding: 0 20px;

        a {
          text-decoration: none;
        }

        @media (max-width: 982px) {
          width: 33%;
        }

        @media (max-width: 750px) {
          width: 50%;
        }

        .productInner {
          text-align: center;
          padding: 10px;

          img {
            width: 90%;
            text-align: center;
            display: inline-block;
            margin-bottom: 10px;
          }

          .productDetails {
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;

            .productName {
              font-size: 14px;
              color: #E64B38;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
              text-align: center;
              height: 41px;
              margin-bottom: 0;
              margin-top: 0;
            }

            .productPrice {
              margin: 10px 0;
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              color: #592E2C;

              .regularPrice {
                margin: 0;
                font-weight: 600;
                font-size: 14px;
                display: inline-block;
                color: #592E2C;

                &.strikeThrough {
                  text-decoration: line-through;
                  // color: #757575;
                  opacity: 0.6;
                  margin-bottom: 0;
                  margin-right: 7px;
                }
              }

              .salePrice {
                display: inline-block;
                margin: 0;
                font-weight: 600;
                color: #592E2C;
                font-size: 14px;

                .saleBox {
                  background-color: $primaryColor;
                  color: #fff;
                  padding: 1px 3px;
                  border-radius: 2px;
                  font-size: 12px;
                  text-transform: uppercase;
                  margin-left: 7px;
                }
              }
            }
          }

          button {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }

          .shopButtonContainer {

            .shopButtonMainContainer {
              background-color: #E64B38;
              border-radius: 50px;
              width: 80%;
              height: 45px;
            }
          }
        }
      }
    }
  }
}