.Tabs {
  width: 100%;
  display: flex;

  .tab {
    flex: 1;
    text-align: center;
    cursor: pointer;
    background-color: #F7F7F7;
    border: 1px solid #E4E4E4;
    padding: 12px;
    color: #989699;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      background-color: #f3f3f3;
    }

    &.selected {
      color: #000;
      background-color: #fff;
      border-top-color: #fff;
      border-radius: 0 0 20px 20px;
    }

    &.selected:hover {
      color: #000;
      background-color: #fff;
    }
  }
}
