@import "../../../../colors";

.ManageDogsModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998;

  .modal-body {
    position: absolute;
    background-color: #fff;
    padding-top: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    min-width: 600px;
    height: calc(100vh - 52px);
    display: flex;
    flex-direction: column;

    .close-button {
      height: 26px;
      width: 26px;
      border-radius: 26px;
      text-align: center;
      cursor: pointer;
      right: 5px;
      top: 5px;
      position: absolute;

      img {
        margin-top: 4px;
        height: 18px;
        margin-left: 1px;
      }
    }

    .close-button:hover {
      background-color: #eee;
    }

    .title {
      margin: 0;
      padding-bottom: 10px;
      margin-left: 20px;
    }

    .modal-content {
      border-top: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      .dogs-list {
        flex: 1;
        overflow-y: auto;

        .dog-container {
          border-bottom: 1px solid $borderLight;
          padding: 20px;
          display: flex;
          flex-direction: row;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          .dog-details-container {
            flex: 1;

            p {
              margin: 5px 0;
            }

            .dog-name {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 7px;
            }

            .dog-details {
              font-size: 14px;
            }
          }

          .completed-modules-container {
            border-radius: 6px;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 6px;
            background-color: $successColor;
            width: 85px;

            p {
              margin: 0;
              color: #fff;
            }

            .completed-modules-amount {
              font-weight: bold;
              font-size: 28px;
            }

            .completed-modules-text {
              font-size: 12px;
            }
          }
        }
      }

      .add-dog-button-container {

        button {
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }

  @media (max-width: 650px) {
    .modal-body {
      min-width: calc(100% - 20px);
    }
  }
}
