@import "../../../../colors";

.BuyCourse {
  padding: 15px 10px;
  overflow-x: hidden;

  .header {
    position: relative;
    display: block;
    width: 100%;

    .main-image {
      background-color: #fff;
      padding-top: 43%;
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: cover;
      position: relative;
      border-radius: 20px 20px 0 0;
    }

    .filter-container {
      background-color: rgba(33, 33, 33, 0.5);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 20px 20px 0 0;

      .filter-text {
        position: relative;
        height: 100%;
        width: 100%;

        h1 {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          text-align: center;
          margin: 0;
          font-size: 35px;

          @media (max-width: 450px) {
            width: 70%;
            margin-left: 10px;
          }
        }

        @media (max-width: 800px) {
          h1 {
            font-size: 26px;
          }
        }
      }
    }
  }

  .course-content {
    margin-top: 20px;

    .imageContainerOuter {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      margin-bottom: 50px;

      .imageContainer {
        display: flex;
        flex-direction: row-reverse;

        .imageSelection {
          flex: 1;
          display: flex;
          flex-direction: column;

          .imagesContainer {
            width: 100%;
            height: 100%;
            flex: 1;
            overflow-y: hidden;

            .mediaItemContainer {
              background-color: #fff;
              margin-bottom: 6px;
            }

            img {
              width: 100%;
              cursor: pointer;
              border: 1px solid #bbb;
              border-radius: 4px;
              display: block;
            }
  
            img:hover {
              opacity: 0.9;
            }
  
            .videoThumbnailContainer {
              width: 100%;
              position: relative;
              cursor: pointer;
              border-radius: 4px;

              img {
                border-radius: 0;
                display: block;
              }
  
              img:hover {
                opacity: 1;
              }
  
              .playIcon {
                position: absolute;
                border: none;
                top: 50%;
                transform: translateY(-50%);
              }
  
              .thumbnailImage {
                border-width: 0;
                border: none;
                margin-top: 11px;
                margin-bottom: 11px;
              }
            }
  
            .imageSelectionButton {
              height: 10px;
              width: 10px;
              border-radius: 10px;
              margin: 10px;
              background-color: #ddd;
              cursor: pointer;
              display: none;
            }
          }
            
          .imageArrowContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 5px;

            .imageArrow {
              flex: 1;
              border: 1px solid $borderLight;
              border-radius: 4px;
              text-align: center;
              padding: 3px 0;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              position: relative;

              &:hover {
                opacity: 0.9;
              }

              .imageArrowClickTarget {
                border-radius: 4px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
              }

              img {
                width: 50%;
              }

              &:first-of-type {
                margin-right: 2px;
              }

              &:last-of-type {
                margin-left: 2px;

                img {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .selectedImage {
          flex: 6;
          padding-left: 10px;
          position: relative;

          .selectedImageContainer {
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;
          }

          img {
            width: calc(100% - 20px);
            border-radius: 4px;
            display: block;
          }

          .videoContainerOuter {
            width: 100%;
            padding-top: 100%;
            position: relative;
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;

            .videoContainer {
              width: 100%;
              padding-top: 56.25%;
              position: absolute;
              margin-top: 50%;
              transform: translateY(-50%);
              top: 0;
  
              .videoInner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
              }
            }
          }
        }

        .selectedImageMobile {
          display: none;
        }
      }

      .desktopDropdownsContainer {
        margin-top: 50px;
      }
    }

    @media (max-width: 500px) {
      .imageContainerOuter {
        margin-bottom: 20px;
        margin-top: -40px;
      }
    }

    .productDetailsContainer {
      display: inline-block;
      width: 50%;
      padding-left: 60px;
      vertical-align: top;

      h1 {
        padding-top: 0;
        line-height: 40px;
        font-size: 32px;
        letter-spacing: 2px;
        color: #333;
        margin: 0;
        font-weight: 600;
        margin-bottom: 15px;
      }

      @media (max-width: 500px) {
        h1 {
          line-height: 24px;
          font-size: 20px;
        }
      }

      .tryFreeText {
        margin: 20px 0 0;
        font-size: 14px;
        font-weight: 600;
        color: $primaryColor;
      }

      .productFreeSpacer {
        margin: 15px 0;
      }

      .subscriptionSavingsLine, .saleSavingsLine {
        margin: 0 0 10px;

        .subscriptionDescriptionText {
          font-size: 14px;
          font-weight: 600;
          color: #757575;
          margin-top: 0;

          .learnMore {
            color: $alternateColor;
            margin-left: 5px;
            font-size: 12px;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .subscriptionSavingsText, .saleSavingsText {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          color: $primaryColor;
        }
      }

      .ratingStarsContainer {
        display: flex;
        // align-items: center;
        margin-bottom: 5px;

        .ratingStarsInner {
          margin-right: 12px;
        }

        .ratingStarsAmount {
          color: #27a2ff;
          font-size: 13px;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .categoriesContainer {
        margin-bottom: 12px;

        .category {
          font-size: 13px;
          display: inline-block;
          border: 1px solid $primaryColorMedium;
          background: $primaryColorMedium;
          margin: 3px;
          padding: 1px 9px;
          border-radius: 10px;
          text-decoration: none;
          color: #131313;

          p {
            margin-bottom: 0;
            margin: 0;
          }
        }
      }

      .description {
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 1.8;
        margin-top: 25px;
        margin-bottom: 16px;
        color: #333;
        letter-spacing: .5px;
      }

      .groupedProductsContainer {
        margin-bottom: 5px;

        img {
          border: 1px solid $borderLight;
          height: 53px;
          width: 53px;
          margin-right: 10px;
          border-radius: 4px;
        }

        .selectedImage {
          border: 1px solid $primaryColor;
        }

        .groupedProductImage {
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      .lowStockRow {

        .lowStock {
          color: #6c6c6c;
          font-size: 13px;
          font-weight: 600;

          .lowStockColor {
            color: orange;
          }
        }
      }

      .outOfStockRow {

        .outOfStock {
          font-size: 13px;
          font-weight: 600;
          color: $primaryColor;
        }
      }

      .oneTimeOrSubRow {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        margin-bottom: 15px;

        button {
          width: 49%;
          border-radius: 0;
          border: 1px solid #fff;
          background-color: transparent;
          color: #333;
          border: 1px solid $primaryColor;
          cursor: pointer;
          border: none;
          padding: 0;
          text-align: left;

          .buttonRadio {
            height: 16px;
            width: 16px;
            border-radius: 16px;
            border: 1px solid $primaryColor;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: -2px;

            .radioInner {
              height: 10px;
              width: 10px;
              border-radius: 10px;
              background-color: $primaryColor;
              margin-top: 2px;
              margin-left: 2px;
            }
          }

          &:hover,
          &:focus {
            background-color: transparent;
          }

          &.unselected {

            .buttonRadio {
              border: 1px solid #bbb;
              
              .radioInner {
                display: none;
              }
            }
          }
        }
      }

      .addToSubscriptionButton {
        margin-top: 16px;
        margin-bottom: 20px;

        button {
          width: 100%;
          padding: 15px;
          font-size: 18px;
        }
      }

      @media (max-width: 1130px) {
        .oneTimeOrSubRow {
          display: flex;
          flex-direction: column;
  
          button {
            width: 100%;
          }

          .oneTimePurchaseButton {
            margin-bottom: 16px;
          }
        }
      }

      .amountContainer {
        border-radius: 4px;
        background-color: $primaryColorLight;
        display: inline-block;
        position: relative;
        line-height: 0;
        margin-bottom: 15px;

        .amountContainerInner {
          display: flex;

          .quantityButton {

            .quantityButtonInner {
              font-size: 30px;
              font-weight: 400;
              height: 31px;
              width: 35px;
              margin: 4px;
              text-align: center;
              background-color: #fff;
              color: #000;
              border-radius: 4px;
              padding: 0;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;

              &:hover {
                background-color: rgba(255, 255, 255, 0.7);
              }
            }
          }

          .quantityAmount {
            color: $primaryColor;
            font-weight: bold;
            border: none;
            width: 35px;
            height: 39px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      }

      .buyRow {
        display: flex;
        flex-direction: row;
        background: #FFFFFF;
        box-shadow: 0px 1px 25px 2px $borderLight;
        border-radius: 4px;
        justify-content: space-between;
        padding: 3px;
        padding-left: 20px;
        margin-top: 20px;

        .priceLine {
          display: flex;
          flex-direction: row;
          align-self: center;
          align-items: center;

          .tryFreeText {
            margin-top: 0;
            margin-right: 15px;
            text-align: center;
          }

          .productPrice {
            font-size: 24px;
            margin: 0;
            margin-right: 20px;
            font-weight: bold;

            &.productSalePrice {
              color: $primaryColor;
            }

            &.originalPrice {
              text-decoration: line-through;
              color: #525252;
              font-weight: 600;
              font-size: 16px;
            }

            @media (max-width: 365px) {
              font-size: 18px;
              margin-right: 10px;

              &.originalPrice {
                font-size: 14px;
              }
            }
          }
        }

        a {
          flex: 1;
          max-width: 210px;
          min-width: 115px;

          button {
            width: 100%;
          }
        }
      }

      .otherActionButtonContainer {
        margin-top: 10px;

        button {
          width: 100%;
          background-color: $alternateColor;
        }
      }

      .mobileDropdownsContainer {
        display: none;
      }
    }

    .dropdownsContainer {
      position: relative;

      .dropdownContainer {

        .dropdownHeaderContainer {
          width: 100%;
          border-radius: 0;
          text-align: left;
          padding-left: 0;
          padding-right: 0;
          position: relative;
          white-space: pre-wrap;
          color: #000;
          border-bottom: 1px solid $borderLight;
          background-color: $transparent;
          margin: 0;

          .buttonIcon {
            color: $primaryColor;
            position: absolute;
            right: 15px;
            top: 20px;
            -webkit-transition: all .3s;
            transition: all .3s;
            font-size: 30px;
            line-height: 0;
            font-weight: 400;
          }

          &.expanded {

            .buttonIcon {
              transform: rotate(180deg);
              top: 22px;
              right: 18px;
            }
          }
        }

        .dropdownHeaderContainer:focus {
          background-color: $transparent;
        }

        .dropdownHeaderContainer:hover {
          background-color: $transparent;
        }

        .dropdownContentContainer {
          -webkit-transition: all .3s;
          transition: all .3s;
          padding: 0 10px;
          border-top: none;
          border-bottom: none;
          height: 0;
          overflow: hidden;
          background-color: #fff;

          &.show {
            padding: 10px;
            height: auto;
          }
        }
      }
    }

    @media (max-width: 850px) {
      .imageContainerOuter {
        width: 100%;
        max-width: 450px;
        margin-left: 50%;
        transform: translateX(-50%);

        .imageContainer {
          flex-direction: column;

          .imageSelection {
            flex: 1;
            align-self: center;
            margin-top: 15px;

            .imagesContainer {
              flex: 1;
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              .mediaItemContainer {
                background-color: $transparent;
                margin-bottom: 0;
              }
      
              img {
                display: none;
              }

              .imageSelectionButton {
                display: inherit;
              }
            }
            
            .imageArrowContainer {
              display: none;
            }
          }

          .desktopImageThumbnail,
          .videoThumbnailContainer {
            display: none;
          }
    
          .selectedImage {
            display: none;
          }

          .selectedImageMobile {
            display: flex;
            flex: 1;
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;

            img {
              border-radius: 4px;
              display: block;
            }

            .videoContainerOuter {
              width: 100%;
              padding-top: 100%;
              position: relative;
              background-color: #fff;
              border-radius: 4px;

              .videoContainer {
                width: 100%;
                padding-top: 56.25%;
                position: absolute;
                margin-top: 50%;
                transform: translateY(-50%);
                top: 0;

                .videoInner {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                }
              }
            }

            img {
              width: 100%;
            }
          }
        }

        .desktopDropdownsContainer {
          display: none;
        }
      }

      .productDetailsContainer {
        width: 100%;
        padding-left: 0;

        .mobileDropdownsContainer {
          display: inherit;
        }
      }
    }

    .productReviewsContainer {
      position: relative;

      h2 {
        margin-top: 0;
        text-align: center;
        font-size: 33px;
        margin-bottom: 29px;
        font-weight: 700;
        color: #333;
        font-weight: 500;
      }

      .dividerLine {
        border-bottom: 1px solid rgba(0,39,59,0.08);
      }

      .reviewsSummaryContainer {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        position: relative;
        align-items: center;

        .reviewsSummaryInner {
          display: flex;
          align-items: center;

          .reviewsAverageText {
            color: #1F1F1F;
            font-size: 30px;
            font-weight: 600;
            margin-right: 7px;
            line-height: 1;
          }

          .ratingStarsInner {
            margin-right: 7px;

            .ratingStarsAmount {
              margin-top: -5px;
              color: #1F1F1F;
              font-size: 12px;
            }
          }
        }

        @media (max-width: 335px) {
          flex-direction: column;

          .reviewsSummaryInner {
            margin-bottom: 12px;
          }
        }

        .writeReviewButtonContainer {

          button {
            background-color: #fff;
            color: $primaryColor;
            border: 1px solid $primaryColor;

            &::before {
              content: "✎";
              margin-right: 10px;
              font-size: 16px;
            }
          }
        }

        @media (max-width: 600px) {
          margin-top: 20px;

          .writeReviewButtonContainer {
            right: auto;
            margin-bottom: 10px;
          }
        }
      }

      .reviewsContainer {

        .reviewContainer {
          margin-top: 10px;
          padding: 20px;

          .reviewerImage {
            background-color: #212121;
            color: #fff;
            height: 45px;
            width: 45px;
            border-radius: 4px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-right: 10px;
          }

          .reviewDetails {
            flex: 1;
            padding-top: 5px;

            .reviewTopRow {
              display: flex;
              justify-content: space-between;

              .reviewerNameRow {
                display: flex;
  
                .reviewerName {
                  margin: 5px 8px 0 0;
                  font-size: 18px;
                  font-weight: 800;
                  line-height: 1;
  
                  .verifiedBuyer {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                    margin-left: 10px;
                  }
                }

                .ratingStarsInner {
                  margin: 5px 0;
                }
              }

              .reviewDate {

                p {
                  margin: 0;
                  font-size: 0.85rem;
                  color: #212121;
                  font-weight: 600;
                }
              }
            }

            .reviewDivider {
              margin: 10px -20px;
              border-bottom: 1px solid $borderDark;
            }

            .reviewTitleContainer {
              margin-bottom: 10px;

              p {
                margin: 0;
                font-weight: 600;
                font-size: 22px;
                color: $primaryColor;
              }
            }

            .reviewContentContainer {
              margin-bottom: 15px;

              p {
                margin: 0;
                font-size: 16px;
                line-height: 210%;
                color: #5E5E5E;
              }
            }

            .reviewBottomRow {
              display: flex;
              justify-content: space-between;

              .reviewHelpfulContainer {
                display: flex;

                p {
                  margin: 0;
                  font-weight: 600;
                  color: #212121;
                  font-size: .88rem;
                }

                .voteContainer {
                  margin-left: 15px;
                  margin-right: 10px;
                  cursor: pointer;
                  filter: grayscale(100%) brightness(0.7);
                  font-size: 13px;
                  margin-top: 2px;
                }
              }
            }

            .commentContainer {
              border-left: 2px solid #ACCEE9;
              padding-left: 10px;

              .dividerLine {
                margin-top: 20px;
              }
            }
          }
        }

        .pageContainer {
          margin-top: 50px;
          display: flex;
          justify-content: space-around;

          .pageText {
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.ratingModalContainer {

  .ratingModalStarsContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
  }

  .inputContainer {
    margin: 5px 0;

    label {
      color: #777777;
      margin-bottom: 0;
      font-size: 14px;
    }

    textarea {
      resize: none;
      display: block;
      font-family: Visby, sans-serif;
    }
  }
}
