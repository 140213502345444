.NewUserOnboarding {
  position: relative;
  padding: 0;
  margin: 0;

  * {
    box-sizing: border-box;
  }

  h1 {
    font-size: 2.9em;
    line-height: 1.2;
    font-weight: 800;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.65;
    font-weight: 500;
    margin: 0 0 1.8em;
  }

  button {
    width: 200px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    padding: 12px 24px;
  }

  .loading-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.6);

    .spinner-container {
      position: absolute;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);
    }
  }
}
