@import "../../../../../colors";

.SoundsTraining {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  .sound-container {
    display: inline-block;
    background-color: #e64b38;
    border-radius: 10px;
    width: 270px;
    height: 170px;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      opacity: 0.75;
    }

    .sound-inner {
      position: relative;
      background-color: #592e2c;
      top: -4px;
      left: -4px;
      border-radius: 10px;

      .lock-icon {
        position: absolute;
        background-color: #fff;
        top: 10px;
        right: 10px;
        border: 2px solid $primaryColor;
        height: 25px;
        width: 25px;
        border-radius: 25px;

        i {
          color: #fff;
        }
      }

      img {
        max-width: 22%;
        filter: brightness(0) invert(1);
      }

    }

    .sound-label-container {
      padding: 0 10px;
      position: relative;
      top: -10px;

      p {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff
      }
    }
  }
}