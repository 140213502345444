.Home {
  .profile-image {
    background-color: #fff;
    padding-top: 43%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: cover;
    position: relative;
    border-radius: 20px 20px 0 0;

    i {
      color: rgba(255, 255, 255, .6);
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      font-size: 18px;
    }

    input {
      visibility: hidden;
    }
  }

  .tab-content-container {
    padding-top: 20px;
  }
}
