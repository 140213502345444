.PasswordStrength {
  position: absolute;
  bottom: 73px;
  background-color: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  font-size: 12px;

  .password-strength-container-inner {
    position: relative;

    .password-strength-title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .password-match-title {
      font-weight: 600;
      font-size: 14px;

      &.match {
        color: #2BB673;
      }

      &.do-not-match {
        color: red;
      }
    }

    .password-strength-row {
      margin: 7px 0 7px 10px;
      display: flex;
      justify-content: space-between;
      color: red;

      &.valid {
        color: #2BB673;
      }

      div {
        margin-right: 30px;
        font-weight: 600;
      }

      img {
        height: 12px;
      }
    }

    .password-reset-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid #bfbfbf;
      bottom: -24px;

      &.password-confirm-arrow {
        bottom: -17px;
      }
    }
  }
}
