.title {
  font-size: 24px;
}

.subtitle {
  font-size: 20px;
}

.paragraph {
  white-space: pre-wrap;
}

.imageContainer {
  text-align: center;

  img {
    width: 100%;
  }
}

.videoContainer {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  .videoInner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
  }
}

.textOverlayImageContainer {
  position: relative;
  margin: 20px 0;

  .textOverlayImage {
    display: block;
  }

  .heading, .subHeading {
    margin: 10px 0;
    font-size: 20px;
  }

  .subHeading {
    font-weight: 500;
    font-size: 16px;
  }
}
