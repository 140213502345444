.JoinAsPro {
  position: relative;
  background-color: #fff;

  .container {
    min-height: calc(100vh - 60px);

    .container-inner {
      position: absolute;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-top: 50px;
      width: 600px;
      padding: 20px;

      p {
        color: #676d73;
        font-weight: 600;
        font-size: 14px;
      }

      form {
        margin-top: 30px;

        input[type=email],
        input[type=password] {
          padding-top: 13px;
          padding-bottom: 13px;
        }

        input[type=submit] {
          border: none;
          font-weight: 700;
          border-radius: 4px;
          width: 100%;
          background-color: #ff3312;
          color: #fff;
          font-size: 24px;
          padding: 11px;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          margin-top: 10px;
        }

        input[type=submit]:hover {
          opacity: .8;
        }

        input[type=submit]:focus {
          outline-width: 0;
        }

        input[type=submit]:disabled {
          background-color: #ddd;

          &:hover {
            opacity: 1;
            cursor: not-allowed;
          }
        }

        .loading-container {
          text-align: center;
          margin-top: 28px;
          margin-bottom: 5px;
        }

        a {
          color: #0072bc;
        }
      }
    }

    @media (max-width: 640px) {
      .container-inner {
        margin-left: 0;
        transform: none;
        width: calc(100% - 40px);
      }
    }
  }
}
