@import "../../../../../colors";

.SoundsNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  margin-top: 10px;
  padding: 10px;

  .navBar {
    display: flex;
    justify-content: space-between;

    .categorySelectContainer {
      width: 200px;
      background-color: $transparent;

      label {
        color: $textColor;
      }

      select {
        background: linear-gradient(45deg, transparent 50%, $textColor 50%), linear-gradient(135deg, $textColor 50%, transparent 50%), linear-gradient(to right, $transparent, $transparent);
        background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        position: relative;
        color: $textColor;
        box-shadow: none;
        border: 2px solid $secondaryColorDark;
        border-radius: 8px;
        background-color: $transparent;
        padding: 8px 12px;
        padding-right: 35px;
        width: 100%;
        font-size: 16px;
        margin: 0;
        box-sizing: border-box;
        line-height: 1.65;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: capitalize;
      }
    }

    .nav-row {
      padding: 25px 0 15px 10px;
      width: 100%;
      overflow-x: auto;
      display: inline-block;
      white-space: nowrap;

      .nav-item {
        display: inline-block;
        color: #333;
        padding: 2px 10px;
        border-radius: 15px;
        margin-right: 10px;
        font-weight: 600;
        color: $alternateColor;
        font-size: 14px;
        cursor: pointer;
        text-transform: capitalize;
        border: 1px solid $alternateColor;

        &.selected {
          background-color: $alternateColor;
          color: #fff;
        }
      }
    }

    .additionalInfoContainerOuter {
      width: 200px;
      margin-top: 22px;
      margin-left: 3px;

      .additionalInfoContainer {
        background: linear-gradient(45deg, transparent 50%, $textColor 50%), linear-gradient(135deg, $textColor 50%, transparent 50%), linear-gradient(to right, $transparent, $transparent);
        background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        position: relative;
        color: $textColor;
        box-shadow: none;
        border: 2px solid $secondaryColorDark;
        border-radius: 8px;
        background-color: $transparent;
        padding: 8px 12px;
        padding-right: 35px;
        width: 100%;
        font-size: 14px;
        margin: 0;
        box-sizing: border-box;
        line-height: 1.65;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .additionalInfoDropdown {
          display: none;
          position: absolute;
          background-color: $backgroundColor;
          top: 40px;
          right: -2px;
          left: -2px;
          border: 2px solid $secondaryColorDark;
          border-radius: 0 0 8px 8px;
          overflow: hidden;
          z-index: 1;

          button {
            text-align: left;
            background-color: $backgroundColor;
            color: $textColor;
            padding: 8px 12px;
            width: 100%;
            font-size: 14px;
            text-transform: none;
            border-bottom: 1px solid $borderDark;
            border-radius: 0;
            font-weight: 400;

            &.lastInfoButton {
              border-bottom-width: 0;
            }
          }
        }

        &:hover {
          border-radius: 8px 8px 0 0;

          .additionalInfoDropdown {
            display: initial;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .modalContainer {

    .sounds-video-container {
      width: 100%;
      padding-top: 56.25%;
      position: relative;


      .sounds-video-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;



      }
    }
  }
}