@import "../../../../colors";

.Sounds {
  height: 100vh;

  .loading-container {
    text-align: center;
    margin-top: 75px;

    p {
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      color: #333;
    }
  }

  .sounds-container {
    padding: 15px 10px;
  }
}
