.Loader {
  display: inline-block;

  img {
    &.x3 {
      height: 150px;
    }

    &.x2 {
      height: 100px;
    }

    &.x1 {
      height: 50px;
    }
  }
}
