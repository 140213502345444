.OpenInMobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000; // sit on top of helpscout beacon

  .bottom-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;

    .bottom-container-header {
      padding: 0 20px;
      text-align: center;
      border-bottom: 1px solid #ccc;

      h1 {
        font-size: 18px;
      }
    }

    .bottom-container-content-container {
      padding: 10px;
      display: flex;
      justify-content: space-around;

      .content {
        width: 100%;
        max-width: 400px;

        .row {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;

          .left-container {
            display: flex;
            align-items: center;

            p {
              font-size: 18px;
              margin: 0;
              color: #868887;
            }

            .browser-icon-container, .pupford-icon-container {
              margin-right: 15px;
              background-color: #aaa;
              border-radius: 50%;
              height: 47px;
              width: 47px;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              img {
                border-radius: 50%;
                height: 47px;
                width: 47px;

                &.browser-icon {
                  height: 35px;
                }
              }
            }
          }

          .right-container {
            display: flex;
            align-items: center;

            button {
              color: #1372B9;
              background-color: #fff;
              border: 1px solid #1372B9;
              width: 110px;
            }
          }
        }
      }
    }
  }
}
