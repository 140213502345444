@import "../../../../colors";

.PhysicalProductModal {
  position: relative;
  width: calc(100vw - 60px);
  margin-top: 10px;

  @media (min-width: 860px) {
    width: 800px;
  }

  .initial-product-container {
    display: flex;
    flex-direction: row;

    .image-container {
      flex: 1;
      padding: 0;

      img {
        width: 100%;
        display: block;
      }
    }

    .initial-right-container {
      flex: 1;
      margin-left: 20px;

      .product-name {
        font-weight: 600;
        font-size: 20px;
        margin-top: 0;
      }

      .loading-container {
        margin-top: 60px;
        text-align: center;

        p {
          font-weight: 600;
          font-size: 16px;
          margin-top: 20px;
          color: #333;
        }
      }
    }

    @media (max-width: 550px) {
      flex-direction: column;

      .initial-right-container {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }

  .product-content {

    .imageContainerOuter {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      .imageContainer {
        display: flex;
        flex-direction: row-reverse;

        .imageSelection {
          flex: 1;
          display: flex;
          flex-direction: column;

          .imagesContainer {
            width: 100%;
            height: 100%;
            flex: 1;
            overflow-y: hidden;

            .mediaItemContainer {
              background-color: #fff;
              margin-bottom: 6px;
            }

            img {
              width: 100%;
              cursor: pointer;
              border: 1px solid #bbb;
              border-radius: 4px;
              display: block;
            }
  
            img:hover {
              opacity: 0.9;
            }
  
            .videoThumbnailContainer {
              width: 100%;
              position: relative;
              cursor: pointer;
              border-radius: 4px;

              img {
                border-radius: 0;
                display: block;
              }
  
              img:hover {
                opacity: 1;
              }
  
              .playIcon {
                position: absolute;
                border: none;
                top: 50%;
                transform: translateY(-50%);
              }
  
              .thumbnailImage {
                border-width: 0;
                border: none;
                margin-top: 11px;
                margin-bottom: 11px;
              }
            }
  
            .imageSelectionButton {
              height: 10px;
              width: 10px;
              border-radius: 10px;
              margin: 10px;
              background-color: #ddd;
              cursor: pointer;
              display: none;
            }
          }
            
          .imageArrowContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 5px;

            .imageArrow {
              flex: 1;
              border: 1px solid $borderLight;
              border-radius: 4px;
              text-align: center;
              padding: 3px 0;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              position: relative;

              &:hover {
                opacity: 0.9;
              }

              .imageArrowClickTarget {
                border-radius: 4px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
              }

              img {
                width: 50%;
              }

              &:first-of-type {
                margin-right: 2px;
              }

              &:last-of-type {
                margin-left: 2px;

                img {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }

        .selectedImage {
          flex: 6;
          padding-left: 10px;
          position: relative;

          .selectedImageContainer {
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;
          }

          img {
            width: calc(100% - 20px);
            border-radius: 4px;
            display: block;
          }

          .videoContainerOuter {
            width: 100%;
            padding-top: 100%;
            position: relative;
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;

            .videoContainer {
              width: 100%;
              padding-top: 56.25%;
              position: absolute;
              margin-top: 50%;
              transform: translateY(-50%);
              top: 0;

              .videoInner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
              }
            }
          }
        }

        .selectedImageMobile {
          display: none;
        }
      }
    }

    .productDetailsContainer {
      display: inline-block;
      width: 50%;
      padding-left: 20px;
      vertical-align: top;

      .product-name {
        font-weight: 600;
        font-size: 20px;
        margin-top: 0;
      }

      .categoriesContainer {
        margin-bottom: 12px;

        .category {
          font-size: 13px;
          display: inline-block;
          border: 1px solid $primaryColorMedium;
          background: $primaryColorMedium;
          margin: 3px;
          padding: 0 9px;
          border-radius: 12px;
          text-decoration: none;
          color: #131313;

          p {
            margin-bottom: 0;
            margin: 0;
          }
        }
      }

      .description {
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 1.8;
        margin-top: 35px;
        margin-bottom: 16px;
        color: #333;
        letter-spacing: .5px;
      }

      a {
        text-decoration: none;
      }

      .view-full-details {
        text-align: center;
        border-top: 1px solid $borderDark;
        border-bottom: 1px solid $borderDark;
        color: #525252;
        text-decoration: none;
        cursor: pointer;
        padding: 10px;
        font-size: 15px;
        font-weight: bold;

        &:hover {
          opacity: 0.9;
        }
      }

      .oneTimeOrSubRow {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
        margin-bottom: 15px;

        button {
          width: 49%;
          border-radius: 0;
          border: 1px solid #fff;
          background-color: transparent;
          color: #333;
          border: 1px solid $primaryColor;
          cursor: pointer;
          border: none;
          padding: 0;
          text-align: left;

          .buttonRadio {
            height: 16px;
            width: 16px;
            border-radius: 16px;
            border: 1px solid $primaryColor;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: -2px;

            .radioInner {
              height: 10px;
              width: 10px;
              border-radius: 10px;
              background-color: $primaryColor;
              margin-top: 2px;
              margin-left: 2px;
            }
          }

          &:hover,
          &:focus {
            background-color: transparent;
          }

          &.unselected {

            .buttonRadio {
              border: 1px solid #bbb;
              
              .radioInner {
                display: none;
              }
            }
          }
        }
      }

      @media (max-width: 1130px) {
        .oneTimeOrSubRow {
          display: flex;
          flex-direction: column;
  
          button {
            width: 100%;
          }

          .oneTimePurchaseButton {
            margin-bottom: 16px;
          }
        }
      }

      .subscriptionSelectContainer {
        margin-bottom: 16px;
        position: relative;
        background-color: #fff;

        .selectArrow {
          color: #333;
          position: absolute;
          right: 15px;
          top: 12px;
        }

        select {
          background-color: transparent;
          color: #333;
          background: transparent;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 800;
          letter-spacing: 0.12em;
          cursor: pointer;

          &:disabled {
            opacity: 1;
            cursor: unset;
          }
        }
      }

      .amountContainer {
        border-radius: 4px;
        background-color: $primaryColorLight;
        display: inline-block;
        position: relative;
        line-height: 0;
        margin-bottom: 15px;

        .amountContainerInner {
          display: flex;

          .quantityButton {

            .quantityButtonInner {
              font-size: 30px;
              font-weight: 400;
              height: 31px;
              width: 35px;
              margin: 4px;
              text-align: center;
              background-color: #fff;
              color: #000;
              border-radius: 4px;
              padding: 0;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;

              &:hover {
                background-color: rgba(255, 255, 255, 0.7);
              }
            }
          }

          .quantityAmount {
            color: $primaryColor;
            font-weight: bold;
            border: none;
            width: 35px;
            height: 39px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
        }
      }

      .buyRow {
        display: flex;
        flex-direction: row;
        background: #FFFFFF;
        box-shadow: 0px 1px 25px 2px $borderLight;
        border-radius: 4px;
        justify-content: space-between;
        padding: 3px;
        padding-left: 20px;

        .priceLine {
          display: flex;
          flex-direction: row;
          align-self: center;
          align-items: center;

          .tryFreeText {
            margin-top: 0;
            margin-right: 15px;
            text-align: center;
          }

          .productPrice {
            font-size: 24px;
            margin: 0;
            margin-right: 20px;
            font-weight: bold;

            &.productSalePrice {
              color: $primaryColor;
            }

            &.originalPrice {
              text-decoration: line-through;
              color: #525252;
              font-weight: 600;
              font-size: 16px;
            }

            @media (max-width: 365px) {
              font-size: 18px;
              margin-right: 10px;

              &.originalPrice {
                font-size: 14px;
              }
            }
          }
        }

        a {
          flex: 1;
          max-width: 210px;
          min-width: 115px;

          button {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 850px) {
      .imageContainerOuter {
        width: 100%;
        max-width: 450px;
        margin-left: 50%;
        transform: translateX(-50%);

        .imageContainer {
          flex-direction: column;

          .imageSelection {
            flex: 1;
            align-self: center;
            margin-top: 15px;

            .imagesContainer {
              flex: 1;
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              .mediaItemContainer {
                background-color: $transparent;
                margin-bottom: 0;
              }

              img {
                display: none;
              }

              .imageSelectionButton {
                display: inherit;
              }
            }

            .imageArrowContainer {
              display: none;
            }
          }

          .desktopImageThumbnail,
          .videoThumbnailContainer {
            display: none;
          }

          .selectedImage {
            display: none;
          }

          .selectedImageMobile {
            display: flex;
            flex: 1;
            background-color: #fff;
            border: 1px solid $borderLight;
            border-radius: 4px;

            img {
              border-radius: 4px;
              display: block;
            }

            .videoContainerOuter {
              width: 100%;
              padding-top: 100%;
              position: relative;
              background-color: #fff;
              border-radius: 4px;

              .videoContainer {
                width: 100%;
                padding-top: 56.25%;
                position: absolute;
                margin-top: 50%;
                transform: translateY(-50%);
                top: 0;

                .videoInner {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                }
              }
            }

            img {
              width: 100%;
            }
          }
        }
      }

      .productDetailsContainer {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
      }
    }
  }
}
