.NewUserOnboardingStep3 {
  position: relative;
  background-image: url(https://pupford.com/wp-content/uploads/2020/02/teaching-place.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: row;

  .filter {
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex: 1;
    text-align: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 40px;
    padding-bottom: 80px;

    .content-container {
      width: 1195px;

      button {
        margin-top: 20px;
      }

      .skip-survey {
        margin-top: 50px;
        
        a {
          color: #fff;
        }
      }
    }

    @media (max-width: 1215px) {
      .content-container {
        width: calc(100% - 40px);
      }
    }
  }
}
