.Course {
    padding: 15px 10px;

    .header {
        position: relative;
        display: inline-block;
        width: 100%;

        .main-image {
            background-color: #fff;
            padding-top: 43%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: cover;
            position: relative;
            border-radius: 20px 20px 0 0;
        }

        .filter-container {
            background-color: rgba(33, 33, 33, 0.5);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border-radius: 20px 20px 0 0;

            .filter-text {
                position: relative;
                height: 100%;
                width: 100%;

                h1 {
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    font-size: 35px;

                    @media (max-width: 450px) {
                        width: 70%;
                        margin-left: 10px;
                    }
                }

                @media (max-width: 800px) {
                    h1 {
                        font-size: 26px;
                    }
                }
            }
        }

        .progress-container {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        @media (max-width: 600px) {
            .progress-container {
                transform: scale(0.8);
                top: -8px;
                right: -8px;
            }
        }
    }

    .back-header-bar {
        background-color: #fff;
        padding: 5px 10px;
        margin-top: -2px;
        display: flex;
        justify-content: space-between;

        .back-button {
            display: flex;
            flex-direction: row;
            text-decoration: none;
            cursor: pointer;
            padding: 8px 10px;
            border-radius: 4px;
            color: #000;
            align-items: center;

            .back-icon {
                height: 17px;
                margin-right: 15px;
                display: inline-block;
            }

            p {
                margin: 0;
                font-weight: 500;
                font-size: 17px;
                display: inline-block;
            }
        }

        .back-button:hover {
            background-color: #eee;
        }
    }

    .module-outer-container {
        margin-left: -5px;
        margin-right: -5px;
        padding: 20px 0;

        h2 {
            margin-left: 1%;
            margin-right: 1%;

            &.module-section-heading {
                border-top: 2px solid #bbb;
                padding-top: 20px;
            }
        }

        .module-inner-container {
            width: calc(50% - 10px);
            margin: 5px;
            position: relative;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            color: #000;
            vertical-align: top;

            .module-container {
                position: relative;
                width: 100%;
                padding-top: 100%;
                background-repeat: no-repeat;
                background-position: 0 50%;
                background-size: cover;
                border-radius: 20px 20px 0 0;

                img {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    height: 25px;
                }
            }

            h3 {
                font-weight: 600;
            }

            @media (max-width: 600px) {
                h3 {
                    font-size: 12px;
                }
            }
        }

        .module-inner-container:hover {
            opacity: 0.9;
        }
    }

    .calendar-container {
        margin-top: 20px;
        margin-right: -10px;

        .day-container {
            position: relative;
            width: calc(20% - 2px);
            padding-top: 20%;
            border: 1px solid #eee;
            border-radius: 5px;
            display: inline-block;
            margin-top: -3px;
            background-color: #fff;
            cursor: pointer;
            color: #000;

            &.completed {
                background-color: #2BB673;
                color: #fff;
            }

            .day-number {
                position: absolute;
                top: 10px;
                left: 10px;
                font-weight: bold;
            }

            .day-title {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                padding: 0 10px;
                text-align: center;
                font-weight: 600;
            }

            @media (max-width: 660px) {
                .day-title {
                    display: none;
                }

                .day-number {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    line-height: 22px;
                }
            }
        }

        .day-container:hover {
            opacity: .8;
        }
    }
}

.CourseLoader {
  position: fixed;
  left: 50%;
  top: 40vh;
  transform: translate(-50%, -50%);
}
