.CompleteModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;

  * {
    box-sizing: border-box;
  }

  .modal-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      z-index: 101;
      background-color: rgba(0, 0, 0, 0.5);
      height: 36px;
      width: 36px;
      border-radius: 36px;

      img {
        margin-top: 6px;
        margin-left: 9px;
        height: 25px;
        filter: brightness(0) invert(1);
      }
    }

    .modal-body {
      position: relative;
      overflow: scroll;
      height: 100vh;

      .modal-width {
        width: 600px;
        margin-left: 50%;
        transform: translateX(-50%);
        color: #fff;
        padding: 70px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        min-height: 100vh;

        h1 {
          text-align: center;
        }

        p {
          line-height: 1.6;
        }

        .modal-button-container {
          text-align: center;
          margin: 40px 0;

          button {
            width: 200px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            padding: 12px 24px;
          }
        }

        .image-container {
          text-align: center;
        }

        .video-container {
          width: 100%;
          padding-top: 56.25%;
          position: relative;

          .video-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
          }
        }

        .text-overlay-image-container {
          position: relative;
          margin: 20px 0;

          h2, h3 {
            margin: 10px 0;
          }

          h3 {
            font-weight: 500;
          }
        }

        .testimonial-container {
          border-radius: 3px;
          background-color: #F6F6F6;
          padding: 25px;
          margin: 20px 0;

          .stars-container {
            text-align: center;

            img {
              height: 25px;
              margin-bottom: 10px;
            }
          }

          .review-paragraph {
            color: #999;

            &.reviewer-name {
              text-transform: uppercase;
              text-align: center;
              font-weight: 600;
              margin-top: 20px;
              margin-bottom: 10px;
            }
          }
        }

        .no-thanks-container {
          text-align: center;

          .no-thanks {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      @media (max-width: 600px) {
        .modal-width {
          width: 100%;
        }
      }
    }
  }
}
