.Login {

  .main-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;

    .left-section {
      flex: 4;
      background-image: url(https://pupford.com/wp-content/uploads/2020/03/pupford-academy-dog-training-scaled.jpg);
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: cover;
      height: 100vh;
      position: relative;

      .left-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 175, 204, 0.5);
        padding-left: 30px;
        padding-right: 20px;

        .left-text-container {
          position: relative;
          width: 100%;
          height: 100%;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          h1 {
            margin-bottom: 10px;
          }

          p {
            font-weight: 600;
            line-height: 22px;
            font-size: 17px;
          }
        }
      }
    }
  
    .right-section {
      flex: 5;
      background-color: #fff;
      padding: 30px;
      padding-top: 10px;
      overflow: auto;

      h1 {
        border-bottom: 4px solid #ff3312;
        padding-bottom: 10px;
        display: inline-block;
        margin-bottom: 15px;
      }

      p {
        line-height: 22px;
      }

      form {
        margin-top: 30px;

        input[type=email],
        input[type=password] {
          padding-top: 13px;
          padding-bottom: 13px;
        }

        input[type=submit] {
          border: none;
          font-weight: 700;
          border-radius: 4px;
          width: 100%;
          background-color: #ff3312;
          color: #fff;
          font-size: 24px;
          padding: 11px;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          margin-top: 10px;
        }

        input[type=submit]:hover {
          opacity: .8;
        }

        input[type=submit]:focus {
          outline-width: 0;
        }

        .loading-container {
          text-align: center;
          margin-top: 28px;
          margin-bottom: 5px;
        }
      }

      .forgot-password {
        text-align: center;
        margin-top: 40px;

        a {
          color: #0072bc;
        }
      }
    }

    @media (max-width: 775px) {
      .left-section {
         display: none;
      }

      .right-section {
         flex: 1;
      }
    }
  }
}
