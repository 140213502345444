@import "../../../../colors";

.Header {
  box-shadow: 0 2px 10px rgba(0,0,0,.25);
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .header-left-container {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      margin-right: 1rem;

      &.menu-bars {
        margin-right: 20px;
        height: 24px;
        cursor: pointer;
      }
    }

    .header-link {
      font-weight: 700;
      font-size: 14px;
      color: #000;
      padding: 0;
      margin: 0 1rem;
      align-self: center;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .user-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 14px;
      padding: 0;
      margin: 0;
      align-self: center;
      margin-right: 20px;
    }

    a {
      text-decoration: none;
    }

    .profile-image-container {
      background-color: #eee;
      border-radius: 50%;
      height: 44px;
      width: 44px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      img {
        height: 44px;
        width: 44px;
        border-radius: 50%;

        &.paw-icon {
          align-self: center;
          cursor: pointer;
          height: 30px;
        }
      }

      &:hover {
        opacity: 0.9;
      }
    }

    @media (max-width: 600px) {
      p {
        display: none;
      }
    }

    .cart-button {
      position: relative;
      height: 44px;
      width: 44px;
      border-radius: 44px;
      background-color: transparent;
      padding: 0;
      margin: 0;
      cursor: pointer;
      vertical-align: middle;
      margin-left: 15px;

      img {
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -50%);
        opacity: 0.6;
        z-index: 1;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      .cart-amount {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;

        .cart-amount-inner {
          height: 18px;
          width: 18px;
          border-radius: 18px;
          background-color: $primaryColor;
          position: relative;

          .cart-amount-text {
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            letter-spacing: 0;
            font-weight: bold;
          }
        }
      }
    }
  }
}
