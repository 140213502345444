.AuthHeader {
  border-bottom: 1px solid #e9eced;
  padding: 13px 20px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;

  a {
    color: #676d73;
    font-size: 14px;
    text-decoration: none;
    margin-top: -5px;
    margin-right: 5px;
  }
}
