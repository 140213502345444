.Module {
    padding: 15px 10px;

    .header {
        position: relative;
        display: inline-block;
        width: 100%;

        .main-image {
            background-color: #fff;
            padding-top: 43%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            background-size: cover;
            position: relative;
            border-radius: 20px 20px 0 0;
        }

        .filter-container {
            background-color: rgba(33, 33, 33, 0.5);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            border-radius: 20px 20px 0 0;

            .filter-text {
                position: absolute;
                left: 20px;
                bottom: 20px;

                h1 {
                    color: #fff;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 10px;
                }

                @media (max-width: 800px) {
                    h1 {
                        font-size: 26px;
                    }
                }

                p {
                    color: #fff;
                    margin: 0;
                    font-weight: 500;
                }
            }
        }
    }

    .back-header-bar {
        padding: 5px 10px;
        margin-top: -2px;
        display: flex;
        justify-content: space-between;

        .back-button {
            display: flex;
            flex-direction: row;
            text-decoration: none;
            cursor: pointer;
            padding: 8px 10px;
            border-radius: 4px;
            color: #000;
            align-items: center;

            .back-icon {
                height: 17px;
                margin-right: 15px;
                display: inline-block;
            }

            p {
                margin: 0;
                font-weight: 500;
                font-size: 17px;
                display: inline-block;
            }
        }

        .back-button:hover {
            background-color: #eee;
        }

        .Loader {
            margin-right: 20px;
            align-self: center;
        }
    }

    .module-container {
        margin-top: 10px;

        .main-section {
            margin-top: 10px;

            .main-section-inner {
                padding: 10px;

                h2 {
                    font-size: 22px;
                    font-weight: 600;
                }

                button {
                    border: none;
                    font-weight: 700;
                    border-radius: 4px;
                    width: 100%;
                    background-color: #ff3312;
                    color: #fff;
                    font-size: 24px;
                    padding: 11px;
                    cursor: pointer;
                    transition: all 200ms ease-in-out;
                    margin-top: 10px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
    
                button:hover {
                    opacity: .8;
                }
    
                button:focus {
                    outline-width: 0;
                }
    
                .video-container {
                    width: 100%;
                    padding-top: 56.25%;
                    position: relative;
    
                    .video-inner {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000;
                    }
                }
    
                .title-divider {
                    height: 1px;
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    background-color: #ddd;
                }
    
                .section-title {
                    font-size: 22px;
                    font-weight: 600;
                }
    
                .paragraph {
                    white-space: pre-wrap;
                }
    
                .Collapsible {
                    margin-bottom: 10px;
                    background-color: #F7F7F8;
                }

                .Collapsible__trigger {
                    display: inherit;
                    cursor: pointer;
                    padding: 13px;
                    font-weight: 600;

                    span {
                        display: flex;
                        justify-content: space-between;
                    }

                    i {
                        transition: all 150ms ease-in-out;
                        font-style: normal;
                        font-size: 20px;

                        &:after {
                            content: "\25BE";
                        }
                    }

                    &.is-open {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }

                .Collapsible__contentInner {
                    padding: 10px;
                }

                .products-container {
                    margin-left: -7px;
                    margin-right: -7px;
                    padding-top: 10px;
    
                    .row {
                        display: flex;
                        align-items: stretch;
    
                        .product-card {
                            padding: 7px;
                            width: 33%;
                            display: flex;
                            position: relative;
    
                            a {
                                color: #000;
                                text-decoration: none;
                                height: 100%;
                                width: 100%;
                                cursor: pointer;
    
                                .product-container {
                                    display: flex;
                                    height: 100%;
                                    flex-direction: column;
                                    border: 1px solid rgba(77, 48, 48, 0.1);
                                    border-radius: 4px;
                                    
                                    .product-image {
                                        width: 100%;
                                        padding-top: 100%;
                                        background-repeat: no-repeat;
                                        background-position: 0 50%;
                                        background-size: cover;
                                        border-radius: 20px 20px 0 0;
    
                                        &.disabled-image {
                                            filter: grayscale(100%);
                                        }
                                    }
    
                                    .product-text {
                                        padding: 20px 10px;
                                        text-align: center;
                                        flex: 1;
    
                                        h3 {
                                            margin: 0;
                                            font-size: 18px;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
    
                        .product-card:hover {
                            opacity: 0.9;
                        }
                    }
    
                    @media (max-width: 600px) {
                        .row {
                            flex-direction: column;
            
                            .product-card {
                                width: calc(100% - 14px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.ModuleLoader {
  position: fixed;
  left: 50%;
  top: 40vh;
  transform: translate(-50%, -50%);
}
