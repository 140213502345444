.NewUserOnboardingStep1 {
  position: relative;
  background-image: url(https://pupford.com/wp-content/uploads/2020/06/dog-on-pink-background.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  padding: 20px;
  text-align: center;

  .filter {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);

    .content-container {
      width: 1234px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      button {
        margin-top: 20px;
      }
    }

    @media (max-width: 1025px) {
      .content-container {
        width: calc(100% - 40px);
      }
    }
  }
}
