.NewUserOnboardingStep4 {
  position: relative;
  background-image: url(https://pupford.com/wp-content/uploads/2019/09/james-barker-v3-zcCWMjgM-unsplash.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: row;

  .filter {
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex: 1;
    text-align: center;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 120px;
    padding-bottom: 80px;

    .content-container {
      width: 868px;

      button {
        margin-top: 20px;
        width: 350px;
      }

      .academy-button-container {
        margin-top: 20px;

        button {
          background-color: #00afcc;
        }
      }

      @media (max-width: 390px) {
        button {
          width: 100%;
        }
      }
    }

    @media (max-width: 920px) {
      .content-container {
        width: calc(100% - 40px);
      }
    }
  }

  @media (max-width: 500px) {
    .filter {
      padding-top: 40px;
      padding-bottom: 80px;
    }
  }
}

