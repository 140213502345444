@import "../../../../../colors";

.SoundsCategory {
  position: relative;
  margin-left: -5px;
  margin-right: -5px;
  text-align: center;

  .sound-container {
    display: inline-block;
    background-color: #e64b38;
    border-radius: 10px;
    width: calc(25% - 15px);
    height: 170px;
    margin-left: 6px;
    margin-right: 6px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 600px) {
      width: calc(33.333% - 10px);
    }

    @media (max-width: 450px) {
      width: calc(50% - 10px);
    }

    &:hover {
      opacity: 0.75;
    }

    .sound-inner {
      position: relative;
      background-color: #592e2c;
      top: -4px;
      left: -4px;
      bottom: 36px;
      border-radius: 10px;



      .lock-icon {
        position: absolute;
        background-color: #fff;
        top: 10px;
        right: 10px;
        border: 2px solid $primaryColor;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        i {
          margin-top: -2px;
          font-size: 12px;
          font-style: normal;

          &:after {
            content: '\1F512';
            filter: brightness(0);
          }
        }
      }

      img {
        max-width: 100%;
        filter: brightness(0) invert(1);
      }
    }

    .sound-label-container {
      padding: 0 10px;
      position: relative;
      top: -10px;

      p {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff
      }
    }
  }
}