.MyCourses {
  position: relative;
  padding: 15px 10px;

  .header-container {
    margin-bottom: 20px;

    .header-image {
      display: block;
    }
  }

  .row {
    display: flex;
    align-items: stretch;
    margin-left: -5px;
    margin-right: -5px;

    .course-card {
      margin: 7px;
      flex: 1;
      display: flex;
      cursor: pointer;
      position: relative;

      a {
        color: #000;
        text-decoration: none;
        height: 100%;
        width: 100%;

        .course-image {
          width: 100%;
          padding-top: 50%;
          background-repeat: no-repeat;
          background-position: 0 50%;
          background-size: cover;
          border-radius: 20px 20px 0 0;
          position: relative;

          img {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 25px;
          }
        }

        .course-text {
          padding: 10px;

          h3 {
            margin: 0;
          }

          p {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
          }
        }
      }

      .buy-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .disabled {
          border-radius: 3px;

          .lock-container {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: space-around;

            i {
              font-size: 20px;
              color: rgba(255, 255, 255, 0.9);
              font-style: normal;

              &:after {
                content: '\1F512';
                filter: brightness(0) invert(1);
              }
            }

            &.large {
              height: 60px;
              width: 60px;
              color: #fff;

              div {
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .course-card:hover {
      opacity: 0.9;
    }
  }

  @media (max-width: 600px) {
    .row {
      flex-direction: column;
    }
  }
}

.MyCoursesLoader {
  text-align: center;
  margin-top: 50px;
}
