@import "../../../../colors";

.paymentContainer {
  border: 1px solid #ddd;
  border-radius: 4px;
  border-bottom: none;

  .paymentTypeContainer {
    border-bottom: 1px solid #ddd;

    &.lastPaymentTypeContainer {
      border-radius: 0 0 4px 4px;
      overflow: hidden;
    }

    .paymentTypeHeading {
      display: flex;
      padding: 10px;
      line-height: 1;

      .paymentHeadingButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        button {
          align-self: center;
          background-color: transparent;
          border: 1px solid #bbb;
          padding: 0;
          height: 16px;
          width: 16px;
          border-radius: 16px;
          margin-right: 15px;

          .selectedProductIndicator {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            margin-left: 2px;
          }

          &.selected {
            border: 1px solid $primaryColor;

            .selectedProductIndicator {
              background-color: $primaryColor;
            }
          }
        }
      }

      p {
        color: #737373;
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        align-self: center;
        margin-right: 15px;
      }

      img {
        margin-right: 5px;
      }
    }

    .paymentTypeBody {
      position: relative;
      padding: 0 10px;
      background-color: #fafafa;
      -webkit-transition: all .3s;
      transition: all .3s;
      height: 0;
      overflow: hidden;

      &.show {
        border-top: 1px solid #ddd;
        padding: 10px;
        height: auto;
      }

      p {
        margin: 0;
        color: #494949;
        font-size: 14px;
      }

      a {
        color: #057daf;
        font-size: 13px;
        cursor: pointer;
        text-decoration: underline;
        transition: all 0s;
      }

      .stripeRegionHidden {
        visibility: hidden;
        height: 0;
      }

      table {
        transition: all 0s;
        margin-bottom: 10px;
        width: 100%;

        thead {
          td {
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 3px;
          }
        }

        td {
          padding-right: 5px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);

          &:first-of-type {
            width: 20px;
            padding-left: 10px;
          }
        }

        .stripeCardRow {
          font-size: 14px;
          font-weight: 500;

          td {
            padding-top: 7px;
            padding-bottom: 7px;

            button {
              transition: all 0s;
              align-self: center;
              background-color: transparent;
              border: 1px solid #bbb;
              padding: 0;
              height: 16px;
              width: 16px;
              border-radius: 16px;
              margin-right: 15px;
              margin-top: 4px;

              .selectedCardIndicator {
                height: 10px;
                width: 10px;
                border-radius: 10px;
                margin-left: 2px;
              }

              &.selected {
                border: 1px solid $primaryColor;

                .selectedCardIndicator {
                  background-color: $primaryColor;
                }
              }
            }
          }
        }
      }

      .stripeRow {
        display: flex;
        flex-direction: row;

        .stripeInputContainer {
          width: 100%;
          margin: 5px;

          label {
            color: #777777;
            margin-bottom: 0;
            font-size: 14px;
          }

          .stripeInputBorder {
            border-radius: 4px;
            border-color: #bfbfbf;
            border: 1px solid #bfbfbf;
            padding: 7px 10px;
            background-color: #fff;
          }
        }
      }
    }
  }
}
